import React, { useState } from "react";
import {  Message } from "rsuite";

function ToastComponent(type, text, toaster) {
  const message = (
    <Message showIcon type={type} closable>
      {text}
    </Message>
  );

  return <>{toaster.push(message, { placement: "topEnd", duration: 5000 })}</>;
}

export default ToastComponent;
