import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";

function useLocalStorage(key, initialValue) {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = () => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == "undefined") {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event("local-storage"));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener("storage", handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener("local-storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("local-storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}
function getObjectDiff(obj1, obj2) {
  let newData = {};
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  diff.forEach((el) => {
    newData[el] = obj1[el];
  });
  return newData;
}
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
const getDate = (item) => {
  const date = new Date(item);
  return item
    ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    : "—";
};
const defaultStartDate = () => {
  var now = new Date();
  now.setDate(now.getDate() - 1);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var localDatetime =
    year +
    "-" +
    (month < 10 ? "0" + month.toString() : month) +
    "-" +
    (day < 10 ? "0" + day.toString() : day) +
    "T" +
    "00" +
    ":" +
    "00";
  return localDatetime;
};
const defaultEndDate = () => {
  var now = new Date();
  var utcString = now.toISOString().substring(0, 19);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var localDatetime =
    year +
    "-" +
    (month < 10 ? "0" + month.toString() : month) +
    "-" +
    (day < 10 ? "0" + day.toString() : day) +
    "T" +
    "23" +
    ":" +
    "59";
  return localDatetime;
};
const defaultStartYeartDate = () => {
  var now = new Date();
  var utcString = now.toISOString().substring(0, 19);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var localDatetime = `${year}-01-01T00:00`;
  return localDatetime;
};
const defaultBackWeekDate = () => {
  const today = new Date();
  if (today === null) {
    throw new Error("today is null");
  }

  const now = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  if (now === null) {
    throw new Error("now is null");
  }

  const year = now.getFullYear();
  if (year === null || isNaN(year)) {
    throw new Error("invalid year");
  }

  const month = now.getMonth() + 1;
  if (month === null || isNaN(month)) {
    throw new Error("invalid month");
  }

  const day = now.getDate();
  if (day === null || isNaN(day)) {
    throw new Error("invalid day");
  }

  const localDatetime = `${year}-${
    month < 10 ? "0" + month.toString() : month
  }-${day < 10 ? "0" + day.toString() : day}T00:00`;
  return localDatetime;
};
const defaultBackMonthDate = () => {
  const today = new Date();
  const now = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var localDatetime =
    year +
    "-" +
    (month < 10 ? "0" + month.toString() : month) +
    "-" +
    (day < 10 ? "0" + day.toString() : day) +
    "T" +
    "00" +
    ":" +
    "00";
  return localDatetime;
};

export {
  useLocalStorage,
  useOnClickOutside,
  getObjectDiff,
  defaultBackWeekDate,
  getDate,
  defaultStartDate,
  defaultStartYeartDate,
  defaultEndDate,
  defaultBackMonthDate,
};
