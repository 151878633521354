import React, { useState, useEffect } from "react";
import "./index.scss";
import Aside from "components/Sidebar";

import {
  Content,
  Container,
  Button,
  Uploader,
  useToaster,
  Col,
  Heading,
  Panel,
  Divider,
  PanelGroup,
  Badge,
  Loader,
  DateRangePicker,
} from "rsuite";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import StatsItem from "./item";
import {
  defaultBackMonthDate,
  defaultBackWeekDate,
  defaultEndDate,
} from "utils/utils";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

function StatsPage() {
  const [orderStats, setOrderStats] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState([
    new Date(defaultBackWeekDate()),
    new Date(defaultEndDate()),
  ]);
  const getOrdersStats = () => {
    setLoading(true);
    // let filters = aggrFilters;
    let params = {};
    if (date?.length > 0) {
      params["dateFrom"] = date[0].toISOString();
      params["dateTo"] = date[1].toISOString();
    }

    // filters.forEach((el) => {
    //   if (el.value && el.value !== "all") {
    //     if (el.type == "date") {
    //       let date = new Date(el.value);
    //       // date.setDate(date.getDate() + 1);
    //       params = {
    //         ...params,
    //         [el.name]: date.toISOString(),
    //       };

    //       return;
    //     }
    //     params = { ...params, [el.name]: el.value };
    //   }
    // });
    Axios.get(`${API_URL}/admin/order/stats`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.data) {
          setOrderStats(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrdersStats();
  }, [date]);
  return (
    <>
      <Container>
        <Aside />
        <Content>
          {loading && <Loader center backgrop size="lg"  />}

          <div className="box-block">
            <Heading level={4} className="d-flex justify-content-between">
              {t("menu.stats")}{" "}
              <DateRangePicker
                format="dd.MM.yyyy HH:mm"
                placement="auto"
                ranges={[
                  {
                    label: t("table.today"),
                    value: [startOfDay(new Date()), endOfDay(new Date())],
                  },
                  {
                    label: t("table.yesterday"),
                    value: [
                      startOfDay(addDays(new Date(), -1)),
                      endOfDay(addDays(new Date(), -1)),
                    ],
                  },
                  {
                    label: t("table.last7days"),
                    value: [
                      startOfDay(subDays(new Date(), 6)),
                      endOfDay(new Date()),
                    ],
                  },
                ]}
                value={date}
                defaultValue={date}
                onChange={(value) => {
                  if (value?.length > 0) {
                    setDate([new Date(value[0]), new Date(value[1])]);
                  } else {
                    setDate([]);
                  }
                }}
              />
            </Heading>
            <div className="stats__info">
              <PanelGroup accordion bordered>
                {orderStats?.length > 0 &&
                  orderStats.map((el, i) => {
                    return (
                      <Panel
                        header={
                          <div>
                            {el.provider}{" "}
                            <Badge
                              color="blue"
                              content={el.total}
                              maxCount={10000000}
                            />
                          </div>
                        }
                        defaultExpanded={true}
                      >
                        {/* <Divider /> */}
                        <div className="stats__info-item">
                          <div className="stats__info-key">
                            {t("heading.finished")}
                          </div>
                          <div className="stats__info-value">{el.finished}</div>
                        </div>
                        <div className="stats__info-item">
                          <div className="stats__info-key">
                            {t("heading.total_orders")}
                          </div>
                          <div className="stats__info-value">{el.total}</div>
                        </div>
                        <br />
                        <StatsItem item={el} />
                      </Panel>
                    );
                  })}
              </PanelGroup>
            </div>
          </div>
        </Content>
      </Container>
    </>
  );
}

export default StatsPage;
