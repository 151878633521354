// import logo from './logo.svg';
import React, { useEffect } from "react";
// import "@coreui/coreui/scss/coreui.scss"
import "./App.scss";
import AppRouter from "./core/router";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "utils/utils";
import classNames from "classnames";
import Favicon from "react-favicon";
import Fav from "./assets/images/favicon.png";
import i18n from "utils/i18n";
import ToastComponent from "components/ToastComponent";
import { useTranslation } from "react-i18next";
import "rsuite/dist/rsuite.min.css";
import { useToaster } from "rsuite";
import { Helmet } from "react-helmet";

function App() {
  const [theme] = useLocalStorage("theme", "light");
  const [locale, setLocale] = useLocalStorage("locale", "ru");
  const { t } = useTranslation();
  const toaster = useToaster();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.code === 401) {
        if (!window.location.href.includes("auth")) {
          window.location.href = "/auth";
          return;
        }
        if (
          error.response.data.message &&
          !error.response.data.message.includes("JWT")
        ) {
          ToastComponent("error", t(error.response.data.message), toaster);
        }
      }
      if (error.response.data.errors) {
        const errors = error?.response?.data?.errors;
        if (errors) {
          errors.forEach((el) => {
            if (el.detail.includes('"') || el.detail.includes(":")) {
              ToastComponent("error", new String(el.detail), toaster);
            } else {
              ToastComponent("error", t(el.detail), toaster);
            }
          });
        }
      } else {
        if (window.location.href.includes("panel")) {
          ToastComponent(
            "error",
            t("common.notifications.fetch_data_error"),
            toaster
          );
        }
      }
    }
  );
  return (
    <>
      <Helmet>
        <title>{t("helmet.title")}</title>
        <meta name="description" content={t("helmet.description")} />
      </Helmet>
      <div
        className={classNames({
          app: true,
          [`${theme}`]: true,
        })}
      >
        <Favicon url={Fav} />
        <AppRouter locale={locale} />
      </div>
    </>
  );
}

export default App;
