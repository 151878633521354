import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Aside from "components/Sidebar";
import CIcon from "@coreui/icons-react";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { toast } from "react-toastify";
import { Content, Container, Button, Uploader, useToaster, Col } from "rsuite";
import ToastComponent from "components/ToastComponent";

function MainPage(props) {
  const { t } = useTranslation();
  const toaster = useToaster();

  const sendFile = (fileList) => {
    let headers = {
      headers: {
        enctype: "application/x-www-form-urlencoded",
      },
    };
    const data = new FormData();
    data.append("csvFile", fileList);
    Axios.post(`${API_URL}/admin/product-price`, data, headers)
      .then((res) => {
        toast.success(t("common.file_success"));
      })
      .catch((err) => {
        toast.error(t("common.file_failed"));
      });
  };

  return (
    <>
      <Container>
        <Aside />

        <Content>
          <div className="box-block">
            <Col xs="4">
              <Uploader
                action={`${API_URL}/admin/product-price`}
                name="csvFile"
                headers={{
                  Authorization: "Bearer " + localStorage.getItem("token"),
                }}
                onSuccess={() => {
                  ToastComponent("success", t("common.file_success"), toaster);
                }}
                onError={() => {
                  ToastComponent("error", t("common.file_failed"), toaster);
                }}
              >
                <Button>{t("common.upload_file_csv")}</Button>
              </Uploader>
            </Col>
          </div>
        </Content>
      </Container>
    </>
  );
}

export default MainPage;
