import React, { useState, useEffect } from "react";

import CIcon from "@coreui/icons-react";
import { cilUser, cilHttps } from "@coreui/icons";
import "./index.scss";
import { Link } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { useTranslation } from "react-i18next";
import ToastComponent from "components/ToastComponent";
import {
  Tabs,
  Content,
  FlexboxGrid,
  Col,
  Heading,
  Input,
  Button,
  Whisper,
  Tooltip,
  InputGroup,
  Stack,
} from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import EmailIcon from "@rsuite/icons/Email";

function AuthPage() {
  const [activeKey, setActiveKey] = useState(1);
  const [user, setUser] = useState("");
  const [visible, setVisible] = React.useState(false);

  const [pass, setPass] = useState("");
  const [currency, setCurrency] = useState("RUB");
  const { t } = useTranslation();
  // const InputGroupAddon = (children) => {
  //   return <InputGroup.Addon>{children}</InputGroup.Addon>;
  // };
  const handleChange = () => {
    setVisible(!visible);
  };
  const login = (e) => {
    e.preventDefault();
    Axios.post(`${API_URL}/login_check`, {
      email: user,
      password: pass,
    })
      .then(function (response) {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          window.location.href = "/";
        }
      })
      .catch((error) => {});
  };
  const register = () => {
    Axios.post(`${API_URL}/register`, {
      email: user,
      password: pass,
      currency: currency,
    })
      .then(function (response) {
        if (response.data.userId) {
          ToastComponent("success", "Регистрация прошла успешно");
          setActiveKey(1);
          // localStorage.setItem('token', response.data.token)
          // window.location.href = '/partner';
        }
      })
      .catch((error) => {
        ToastComponent("error", error.response.data.message);
      });
  };

  return (
    <Content className="auth">
      <FlexboxGrid align="middle" justify="center">
        <div className="auth__wrapper">
          <Tabs defaultActiveKey="1" vertical appearance="subtle">
            <Tabs.Tab eventKey="1" title={t("common.login")}>
              <form onSubmit={(e)=> login(e)}>
                {" "}
                <Heading level={5}>{t("login.title")}</Heading>
                <br />
                <Stack spacing={10} direction="column" alignItems="flex-start">
                  <label>{t("common.username")}</label>

                  <Whisper
                    trigger="hover"
                    speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
                  >
                    <InputGroup inside>
                      <Input
                        placeholder={t("common.username")}
                        value={user}
                        required
                        onChange={(value) => setUser(value)}
                      />

                      <InputGroup.Addon>
                        <EmailIcon />
                      </InputGroup.Addon>
                    </InputGroup>
                  </Whisper>
                </Stack>
                <br />
                <Stack spacing={10} direction="column" alignItems="flex-start">
                  <label>{t("common.password")}</label>
                  <Whisper
                    trigger="hover"
                    speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
                  >
                    <InputGroup inside>
                      <Input
                        type={visible ? "text" : "password"}
                        value={pass}
                        required
                        onChange={(value) => setPass(value)}
                      />

                      <InputGroup.Button onClick={handleChange}>
                        {visible ? <EyeIcon /> : <EyeSlashIcon />}
                      </InputGroup.Button>
                    </InputGroup>
                  </Whisper>
                </Stack>
                <br />
                <div className="auth__footer">
                  {/* <Button appearance="default">{t("common.cancel")}</Button> */}
                  <Button appearance="primary" type="submit">
                    {t("common.login")}
                  </Button>
                </div>
              </form>
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title={t("common.register")} disabled>
              <></>
            </Tabs.Tab>
          </Tabs>
        </div>
      </FlexboxGrid>
    </Content>
  );
}

export default AuthPage;
