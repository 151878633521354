import React, { useState, useEffect } from "react";
import "./index.scss";
import Aside from "components/Sidebar";

import {
  Content,
  Container,
  Heading,
  Loader,
  Toggle,
} from "rsuite";
import { API_URL } from "utils/settings";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { List } from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";

function ProvidersPage() {
  const [list, setList] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const getProviders = () => {
    setLoading(true);
    let params = {};
    Axios.get(`${API_URL}/admin/provider/list`, {
      params: params,
    })
      .then(function (response) {
        if (response.data.providerList?.length > 0) {
          setList(response.data.providerList);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getProviders();
  }, []);
  const handleProvider = (val, uid) => {
    setLoading(true);
    Axios.put(`${API_URL}/admin/provider/enabled`, {
      providerUid: uid,
      enabled: val,
    })
      .then(function (response) {
        getProviders();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Container>
        <Aside />
        <Content>
          {loading && <Loader center backgrop size="lg" />}

          <div className="box-block">
            <Heading level={4} className="d-flex justify-content-between">
              {t("menu.providers")}{" "}
            </Heading>
            <br />
            {list?.length > 0 && (
              <List style={{ maxWidth: "500px" }} bordered hover>
                {list.map((el) => {
                  return (
                    <List.Item key={el.uid} style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>{el.uid}</span>
                        <Toggle
                          checkedChildren={<CheckIcon />}
                          unCheckedChildren={<CloseIcon />}
                          checked={el.enabled}
                          disabled={loading}
                          onChange={(value) => handleProvider(value, el.uid)}
                        />
                      </div>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </div>
        </Content>
      </Container>
    </>
  );
}

export default ProvidersPage;
