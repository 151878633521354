import React, { useState, useEffect } from "react";
import { DateRangePicker, Pagination, Table } from "rsuite";
import { useTranslation } from "react-i18next";
import { enCountries, ruCountries } from "utils/countries";
function StatsItem({ item }) {
  const { Column, HeaderCell, Cell } = Table;
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  //   const [data, setData] = useState(null);
  const handleChangePerPage = (dataKey) => {
    setPage(1);
    setPerPage(dataKey);
  };
  const countriesObj = {
    ru: ruCountries,
    en: enCountries,
  };

  //   useEffect(() => {
  //     if (item?.productAggregation?.length > 0) {
  //       handleData();
  //     }
  //   }, [item]);
  //   useEffect(() => {
  //     handleData();
  //   }, [page]);
  const CountryCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <div className="country-block">
          <div class={`flag flag-${rowData.country}`} />
          <span>{t(`country.${rowData.country}`)}</span>
        </div>
      </Cell>
    );
  };
  const ServiceCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} style={{ padding: "6px" }}>
        <div className="service-block">
          <div class={`bg bg-${rowData.service}`} />
        </div>
      </Cell>
    );
  };
  const data = item.productAggregation.filter((v, i) => {
    const start = perPage * (page - 1);
    const end = start + perPage;
    return i >= start && i < end;
  });

  return (
    <>
      {/* {data?.length > 0 && ( */}
      <>
        <Table data={data} autoHeight>
          <Column  align="start" flexGrow={1} width="25%">
            <HeaderCell>{t(`table.country`)}</HeaderCell>
            <CountryCell dataKey="country" />
          </Column>
          <Column align="start" flexGrow={1} width="25%">
            <HeaderCell>{t(`table.service`)}</HeaderCell>
            <ServiceCell dataKey="service" />
          </Column>
          <Column align="start" flexGrow={1} width="25%">
            <HeaderCell>{t(`table.total`)}</HeaderCell>
            <Cell dataKey="total" />
          </Column>
          <Column align="start" flexGrow={1} width="25%">
            <HeaderCell>{t(`table.finished`)}</HeaderCell>
            <Cell dataKey="finished" />
          </Column>
        </Table>

        {/* {item?.productAggregation?.length > 5 && ( */}
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={item.productAggregation?.length}
            limitOptions={[5,10, 15, 20, 25, 30, 35, 45, 50]}
            limit={perPage}
            locale={{
      
              limit: `{0} / ${t("table.pagination_pages")}`,
              total: `${t("table.total")}: {0}`,
              skip: `${t("table.go_to")}{0}`,
            }}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangePerPage}
          />
        </div>
        {/* )} */}
      </>
      {/* )} */}
    </>
  );
}
export default StatsItem;
